import { LargeHeadingStyled } from "../../../components/custom/texts/texts.styled";
import { Button, Card, Drawer, Dropdown, Flex, Form, Icon } from "@nubeteck/components";

import { Input, Layout, Select, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { FormInstance } from "antd/lib/form";
import { toastErrorStyle, toastSuccessStyle } from "src/constants";
import {
  useCreateResolutionMutation,
  useGetResolutionsMutation,
  useUpdateResolutionMutation,
  useGetResolutionsStatusMutation,
} from "src/services";
import { ArrayUtils } from "@nubeteck/utils";

const { Content } = Layout;

interface IResolution {
  resolucionId: number;
  estadoId: number;
  resolucionNombre?: string;
  nombre?: string;
}

const Resolutions: React.FC = () => {
  const [form] = Form.useForm<IResolution>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [resolucionId, setResolucionId] = React.useState(0);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const formRef = React.useRef<FormInstance>(null);
  const { resetFields, setFieldsValue, validateFields } = form;
  const [getResolutions, { data, isLoading }] = useGetResolutionsMutation();
  const [getResolutionsStatus, { data: statusList, isLoading: isLoadingStatus }] =
    useGetResolutionsStatusMutation();
  const [
    createResolution,
    { isLoading: isLoadingCreate, isSuccess: isSuccessCreate, error: errorCreate },
  ] = useCreateResolutionMutation();
  const [
    updateTemplate,
    { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate, error: errorUpdate },
  ] = useUpdateResolutionMutation();
  // const [
  //   changeResolutionState,
  //   { isLoading: isLoadingState, isSuccess: isSuccessState, error: errorState },
  // ] = useDeleteResolutionMutation();

  const estatusOptions = ArrayUtils.selectLabelValue(
    statusList?.data ?? [],
    "estadoNombre",
    "estadoId",
  );

  useEffect(() => {
    getResolutionsStatus();
  }, [getResolutionsStatus]);

  useEffect(() => {
    getResolutions();
  }, [getResolutions, isSuccessCreate, isSuccessUpdate, errorCreate, errorUpdate]);

  useEffect(() => {
    setDrawerOpen(false);
    if (errorCreate) toast.error(`Error: ${errorCreate.data?.message}`, toastErrorStyle);

    if (isSuccessCreate) {
      toast.success("Registro almacenado exitósamente", toastSuccessStyle);
      resetFields();
      setResolucionId(0);
    }
  }, [isSuccessCreate, resetFields, errorCreate]);

  useEffect(() => {
    setDrawerOpen(false);
    if (errorUpdate) toast.error(`Error: ${errorUpdate.data?.message}`, toastErrorStyle);

    if (isSuccessUpdate) {
      toast.success("Registro almacenado exitósamente", toastSuccessStyle);
      resetFields();
      setResolucionId(0);
    }
  }, [isSuccessUpdate, resetFields, errorUpdate]);

  // useEffect(() => {
  //   if (errorState) {
  //     toast.error(`Error: ${errorState.data?.message}`, toastErrorStyle);
  //   }

  //   if (isSuccessState) {
  //     toast.success("Estado actualizado correctamente.", toastSuccessStyle);
  //   }
  // }, [errorState, isSuccessState]);

  const onClickEditarResolucion = React.useCallback(
    (record: IResolution) => {
      setResolucionId(record.resolucionId);
      setFieldsValue({ nombre: record.resolucionNombre, estadoId: record.estadoId });
      setDrawerOpen(true);
    },
    [setFieldsValue],
  );

  // const cambiarEstadoResolucion = React.useCallback(
  //   (value: number, record: IResolution) => {
  //     changeResolutionState({ nombre: record.resolucionNombre, estadoId: value !== 12 ? 12 : 11 });
  //   },
  //   [changeResolutionState],
  // );

  const submit = () => {
    validateFields(["nombre", "estadoId"])
      .then((values) => {
        const v = {
          ...values,
        };
        if (resolucionId !== 0) {
          updateTemplate({
            ...v,
            id: resolucionId,
          });
        } else {
          createResolution(v);
        }
      })
      .catch(() => {
        toast.error(`Algunos campos necesitan ser revisados.`);
      });
  };

  const columns = React.useMemo<ColumnsType<IResolution>>(
    () => [
      {
        title: "Resolucion",
        dataIndex: "resolucionNombre",
      },
      {
        title: "Estado",
        dataIndex: "estadoNombre",
      },
      {
        title: "",
        dataIndex: "actions",
        fixed: "right",
        width: 100,
        render: (_, record) => (
          <Dropdown
            className="cursor-pointer"
            key={record.resolucionId}
            trigger={["click"]}
            align={{ offset: [-40, 4] }}
            menu={{
              items: [
                {
                  key: "1",
                  label: "Editar",
                  icon: <Icon name="" />,
                  onClick: () => onClickEditarResolucion(record),
                },
                // {
                //   key: "2",
                //   label: record.estadoId !== 12 ? "Inactivar" : "Activar",
                //   icon: <Icon name={record.estadoId !== 12 ? "" : ""} />,
                //   onClick: () => cambiarEstadoResolucion(record.estadoId, record),
                // },
              ],
            }}
          >
            <Icon name="" isTouchable />
          </Dropdown>
        ),
      },
    ],
    [onClickEditarResolucion],
  );

  return (
    <Flex style={{ flexDirection: "column", width: "100%", alignSelf: "flex-start" }}>
      <Content style={{ marginTop: "16px" }}>
        <Flex style={{ width: "100%", justifyContent: "space-between" }}>
          <LargeHeadingStyled>Resoluciones</LargeHeadingStyled>

          <Button
            title=""
            type="primary"
            loading={false}
            onClick={() => {
              setDrawerOpen(true);
              resetFields();
            }}
          >
            Nueva resolución
          </Button>
        </Flex>
        <Card bodyStyle={{ padding: 0, width: "100%" }}>
          <Table<IResolution>
            loading={isLoading || isLoadingCreate || isLoadingUpdate}
            columns={columns}
            dataSource={data?.data}
            rowKey={(v) => v.resolucionId}
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true,
              style: { marginRight: "30px" },
            }}
            scroll={{ x: 800 }}
          />
          <Drawer
            placement="right"
            title={resolucionId !== 0 ? "Edición de resolución" : "Nueva resolución"}
            open={drawerOpen}
            onClose={() => {
              setResolucionId(0);
              setDrawerOpen(false);
              resetFields();
            }}
          >
            {" "}
            <Form
              form={form}
              layout="vertical"
              ref={formRef}
              style={{ display: "flex", flexDirection: "column", gap: 16 }}
              initialValues={{
                id: 1,
              }}
            >
              <Form.Item
                name="nombre"
                label="Nombre de la resolución"
                hasFeedback
                required
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <Input type="text" />
              </Form.Item>
              <Form.Item
                name="estadoId"
                label="Estado"
                hasFeedback
                required
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <Select
                  loading={isLoadingStatus}
                  size="large"
                  options={estatusOptions}
                  placeholder="Estado de la resolución"
                />
              </Form.Item>

              <Button
                onClick={() => submit()}
                type="primary"
                size="large"
                htmlType="submit"
                style={{ alignSelf: "flex-end" }}
                loading={isLoadingCreate || isLoadingUpdate}
              >
                Guardar
              </Button>
            </Form>
          </Drawer>
        </Card>
      </Content>
    </Flex>
  );
};

export default Resolutions;
