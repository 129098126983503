import { Box, Button, Icon, Image, Tooltip } from "@nubeteck/components";
import { Dropdown, MenuProps, Radio } from "antd";
import React, { useEffect } from "react";
import SubjectImg from "src/assets/SubjectImg.svg";
import ServiceImg from "src/assets/ServiceImg.svg";
import {
  ActivePriceStyled,
  DropdownIcon,
  InactivePriceStyled,
  MoreDetailsButtonStyled,
  ResumeCardDuesCheckContainer,
  ResumeCardIconContainer,
  ResumeCardStyled,
  ResumeFlexStyled,
} from "./resumeCard.styled";
import { ICartItem } from "@/Interfaces/cart";
import { FormatterUtils } from "@nubeteck/utils";

interface IResumeCard extends ICartItem {
  title: string;
  codigo: string;
  type: "subject" | "service" | "balance";
  items: (data: any) => MenuProps["items"];
  isLoading?: boolean;
  fechaVencimiento?: string;
  marcarParaCuotas?: (matriculaId: number, costo: number) => void;
  pagaCuotas?: boolean;
}

const ResumeCard = ({
  type,
  title,
  sePuedePagarEnCuotas,
  marcarParaCuotas,
  codigo,
  horarios,
  costoOriginal,
  iva,
  asignaturaId,
  costoConDescuentos,
  descuentoBeca,
  descuentoPatrocinio,
  items,
  esObligatorio,
  matriculaId,
  fechaVencimiento,
  costo,
  monedaId,
  seccionId,
  pagaCuotas,
  solicitudId,
  reglaTipoId,
}: IResumeCard) => {
  const [isCardExpanded, setCardExpanded] = React.useState<boolean>(false);
  const [itemsArray, setItemsArray] = React.useState<any>([]);

  React.useEffect(() => {
    setItemsArray(
      items({
        codigo,
        nombre: title,
        horarios,
        costoOriginal,
        type,
        solicitudId,
        asignaturaId,
        esObligatorio,
        matriculaId,
        seccionId,
      }),
    );
  }, [
    items,
    setItemsArray,
    asignaturaId,
    codigo,
    title,
    horarios,
    costoOriginal,
    type,
    esObligatorio,
    matriculaId,
    seccionId,
    solicitudId,
  ]);

  useEffect(() => {
    if (marcarParaCuotas && reglaTipoId === 2 && !pagaCuotas) {
      marcarParaCuotas(matriculaId, costoConDescuentos ?? costo ?? costoOriginal ?? 0);
    }
  }, [
    marcarParaCuotas,
    reglaTipoId,
    matriculaId,
    costoConDescuentos,
    costo,
    costoOriginal,
    pagaCuotas,
  ]);

  const getSesion = () => {
    return monedaId === 2 ? "₡" : "$";
  };

  const getPaymentOptions = () => {
    if (reglaTipoId === 2) {
      return [{ label: "Pagar en cuotas", value: true }];
    } else {
      return [
        { label: "Pagar al contado", value: false },
        { label: "Pagar en cuotas", value: true },
      ];
    }
  };

  return (
    <ResumeCardStyled
      className={isCardExpanded ? (type === "service" ? "is-service-expanded" : "is-expanded") : ""}
    >
      <ResumeFlexStyled>
        <Box className={`img-container ${isCardExpanded ? "is-expanded" : ""}`}>
          <Image preview={false} src={type === "subject" ? SubjectImg : ServiceImg} width={115} />
        </Box>
        <Box style={{ maxWidth: 255 }}>
          <h4>{title}</h4>
          {type !== "balance" ? (
            <span>
              <strong>Código: </strong>
              {codigo}
            </span>
          ) : (
            <span>
              <strong>Fecha vencimiento: </strong>
              {fechaVencimiento}
            </span>
          )}
          {isCardExpanded && (
            <Box>
              {horarios != null && (
                <>
                  <h5>
                    <strong>Horario:</strong>
                  </h5>
                  <ul className="horarios">
                    {horarios?.map((hor) => (
                      <li
                        key={`${hor.diaId} ${hor.horaInicio} ${hor.horaFin}`}
                      >{`${hor.diaNombre}, ${hor.horaInicio} - ${hor.horaFin}`}</li>
                    ))}
                  </ul>
                  <span>
                    <strong>Precio original: </strong>
                    {`${getSesion()} ${
                      costoOriginal && costoOriginal > 0
                        ? FormatterUtils.number(costoOriginal.toFixed(2))
                        : 0
                    }`}
                  </span>
                  <div>
                    <strong>IVA: </strong>
                    {`${getSesion()} ${iva && iva > 0 ? FormatterUtils.number(iva.toFixed(2)) : 0}`}
                  </div>
                </>
              )}

              <ul className="descuentos">
                <li>
                  <strong>(-) Beca </strong>
                  {`${getSesion()} ${
                    descuentoBeca > 0 ? FormatterUtils.number(descuentoBeca.toFixed(2)) : 0
                  }`}
                </li>

                <li>
                  <strong>(-) Patrocinio </strong>
                  {`${getSesion()} ${
                    descuentoPatrocinio > 0
                      ? FormatterUtils.number(descuentoPatrocinio.toFixed(2))
                      : 0
                  }`}
                </li>
              </ul>
            </Box>
          )}
          {type !== "balance" && (
            <Box>
              <MoreDetailsButtonStyled
                type="link"
                onClick={() => {
                  setCardExpanded(!isCardExpanded);
                }}
              >
                {isCardExpanded ? "Ocultar detalles" : "Ver más"}
              </MoreDetailsButtonStyled>
            </Box>
          )}
          {descuentoBeca > 0 || descuentoPatrocinio > 0 ? (
            <>
              <ActivePriceStyled>{`${getSesion()} ${
                costoConDescuentos && costoConDescuentos > 0
                  ? FormatterUtils.number(costoConDescuentos.toFixed(2))
                  : 0
              }`}</ActivePriceStyled>{" "}
              {isCardExpanded && (
                <InactivePriceStyled>
                  {`${getSesion()} ${
                    (costoOriginal && costoOriginal > 0) || (costo && costo > 0)
                      ? FormatterUtils.number((costo ?? costoOriginal ?? 0).toFixed(2))
                      : 0
                  }`}
                </InactivePriceStyled>
              )}
            </>
          ) : (
            <div>
              <ActivePriceStyled>{`${getSesion()} ${
                (costoOriginal && costoOriginal > 0) || (costo && costo > 0)
                  ? FormatterUtils.number((costo ?? costoOriginal ?? 0).toFixed(2))
                  : 0
              }`}</ActivePriceStyled>
            </div>
          )}
        </Box>
      </ResumeFlexStyled>
      {itemsArray &&
      itemsArray?.length > 0 &&
      (type === "subject" || (type === "service" && !esObligatorio)) ? (
        itemsArray.length > 1 ? (
          <Dropdown
            trigger={["click"]}
            menu={{
              items: items({
                codigo,
                nombre: title,
                horarios,
                costoOriginal,
                type,
                asignaturaId,
                esObligatorio,
                seccionId,
              }),
            }}
          >
            <ResumeCardIconContainer>
              <DropdownIcon name="" />
            </ResumeCardIconContainer>
          </Dropdown>
        ) : (
          <ResumeCardIconContainer>
            <Button
              type="text"
              icon={itemsArray[0]?.icon}
              style={{ padding: 0 }}
              onClick={itemsArray[0]?.onClick}
            ></Button>
          </ResumeCardIconContainer>
        )
      ) : null}
      {sePuedePagarEnCuotas && marcarParaCuotas && pagaCuotas !== undefined ? (
        <ResumeCardDuesCheckContainer>
          <Radio.Group
            value={reglaTipoId === 2 ? true : pagaCuotas}
            options={getPaymentOptions()}
            onChange={() =>
              marcarParaCuotas(matriculaId, costoConDescuentos ?? costo ?? costoOriginal ?? 0)
            }
          />
          <Tooltip title="Esta materia se dividirá en tres pagos (cuotas).">
            <Button type="text" size="small">
              <Icon name="" size={20} colorPrimary />
            </Button>
          </Tooltip>
        </ResumeCardDuesCheckContainer>
      ) : null}
    </ResumeCardStyled>
  );
};

export default ResumeCard;
