import { api } from "./apiBaseQuery";

export const genericServices = api.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentSubjects: builder.query({
      query: () => {
        return {
          url: "Estudiantes/Asignaturas",
        };
      },
    }),
    getUserInfo: builder.mutation({
      query: () => {
        return {
          url: "/Estudiantes/InfoGeneral",
          method: "GET",
        };
      },
    }),
    getAdminCareers: builder.query({
      query: () => {
        return {
          url: "Admins/Carreras",
        };
      },
    }),
    getAdminPeriods: builder.query({
      query: (anio) => {
        return {
          url: `Admins/Periodos/?anio=${anio}`,
        };
      },
    }),
    getAdminPeriodsWithYear: builder.query({
      query: () => {
        return {
          url: `Periodos`,
        };
      },
    }),
    getCareers: builder.query({
      query: (esPrematricula) => {
        return {
          url: `Estudiantes/Carreras?esPreMatricula=${esPrematricula}`,
        };
      },
    }),
    getCareersByStudentId: builder.mutation({
      query: (estudianteId) => {
        return {
          url: `Admins/Estudiantes/CarrerasConNombre/${estudianteId}`,
          method: "GET",
        };
      },
    }),
    getFinantialEntities: builder.query({
      query: () => {
        return {
          url: "/EntidadesFinancieras",
        };
      },
    }),
    getCurrencies: builder.query({
      query: () => {
        return {
          url: "/Caja/Monedas",
        };
      },
    }),
    getRoles: builder.query({
      query: () => {
        return {
          url: "/Usuarios/Roles",
        };
      },
    }),
    getPaymentTypes: builder.query({
      query: () => {
        return {
          url: "/Admins/PlazosTipos",
        };
      },
    }),
    getPeriodsWithoutRulesMessage: builder.query({
      query: () => {
        return {
          url: "/Admins/MensajePeriodosSinRegla",
        };
      },
    }),
    getSponsorships: builder.query({
      query: () => {
        return {
          url: "/Patrocinios/Obtener/Patrocinadores",
        };
      },
    }),
    getStudents: builder.mutation({
      query: (body) => {
        return {
          url: "/Patrocinios/Obtener/Estudiantes/",
          method: "POST",
          body,
        };
      },
    }),
    uploadFile: builder.mutation({
      query: (body) => {
        return {
          url: "Files",
          method: "POST",
          body,
          respondeHandler: "text",
        };
      },
    }),
  }),
});

export const {
  useGetFinantialEntitiesQuery,
  useGetUserInfoMutation,
  useGetCurrentSubjectsQuery,
  useGetCareersQuery,
  useGetCareersByStudentIdMutation,
  useGetAdminCareersQuery,
  useGetAdminPeriodsQuery,
  useGetAdminPeriodsWithYearQuery,
  useGetRolesQuery,
  useGetPaymentTypesQuery,
  useGetPeriodsWithoutRulesMessageQuery,
  useUploadFileMutation,
  useGetSponsorshipsQuery,
  useGetStudentsMutation,
  useGetCurrenciesQuery,
} = genericServices;
