import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@nubeteck/components";
import { UserHelp } from "../../other";
import { LogoCenfotecBlancoPNG } from "../../../assets";
import {
  HeaderStyled,
  HomeLogo,
  MenuStyled,
  MobileMenuButtonStyled,
  UserSectionStyled,
  HeaderText,
} from "./header.styled";
import { IMenuItem } from "src/Interfaces/options";

interface IHeader {
  menuItems: (IMenuItem | null)[];
  userName: string;
  openMenu: () => void;
  isHelpVisible: boolean;
  isAdmin: boolean;
  isFakeSelecting: boolean;
  logout: () => void;
  logoutImpersonated: () => void;
}

const UserSection = ({
  userName,
  logout,
  isHelpVisible,
  isAdmin,
}: {
  userName: string;
  logout: () => void;
  isHelpVisible: boolean;
  isAdmin: boolean;
}) => (
  <UserSectionStyled>
    {!isAdmin && <>{/* <Notifications /> */}</>}
    {isHelpVisible && <UserHelp />}
    <HeaderText
      style={{ maxWidth: "145px", display: "block", textOverflow: "ellipsis", overflow: "hidden" }}
    >
      {userName}
    </HeaderText>
    <Icon isTouchable={true} name="power_settings_new" color="white" onClick={logout} />
  </UserSectionStyled>
);

const Header = ({
  menuItems,
  userName,
  openMenu,
  isHelpVisible,
  isAdmin,
  isFakeSelecting,
  logout,
  logoutImpersonated,
}: IHeader) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <HeaderStyled title="Cenfotec Autoservicio">
      <HomeLogo
        src={LogoCenfotecBlancoPNG}
        width={75}
        preview={false}
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/");
        }}
      />
      <HeaderText>AUTOSERVICIO</HeaderText>
      <MenuStyled>
        {menuItems.map(
          (item) =>
            item && (
              <li key={item.name}>
                <NavLink
                  title={item.tooltip}
                  onClick={
                    isFakeSelecting
                      ? () => {
                          logoutImpersonated();
                          navigate(item.link);
                          window.location.reload();
                        }
                      : () => null
                  }
                  to={item.link}
                  end
                  className={
                    item?.exactLink
                      ? location.pathname.split("/")[1] === item.exactLink
                        ? "is-active"
                        : ""
                      : ({ isActive }) => (isActive ? "is-active" : "")
                  }
                >
                  {item.name}
                </NavLink>
              </li>
            ),
        )}
      </MenuStyled>
      <UserSection
        userName={userName}
        logout={logout}
        isHelpVisible={isHelpVisible}
        isAdmin={isAdmin}
      />
      <MobileMenuButtonStyled type="text" onClick={openMenu}>
        <Icon name="" size={32} />
      </MobileMenuButtonStyled>
    </HeaderStyled>
  );
};

export default Header;
