import { api } from "./apiBaseQuery";

export const chargesSettingsServices: any = api.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentsComparison: builder.mutation({
      query: (body) => {
        return {
          url: `Pagos`,
          method: "POST",
          body,
        };
      },
    }),
    getCartAdmin: builder.mutation({
      query: (params) => {
        return {
          url: `Caja/${params.id}?esPagoCuotas=${
            params.materiasCuotas !== null && params.materiasCuotas?.length > 0
          }`,
          method: "POST",
          body: { matriculasParaCuotas: params.materiasCuotas },
        };
      },
    }),
    assignCartToCashier: builder.mutation({
      query: (materias) => {
        return {
          url: `Caja/PagarPresencial`,
          method: "POST",
          body: { matriculasParaCuotas: materias },
        };
      },
    }),
    deleteCartItemAdmin: builder.mutation({
      query: (body) => {
        const id = body.peopleId;
        delete body.peopleId;
        return {
          url: `/Caja/Admin/EliminarItem/${id}`,
          method: "POST",
          body,
        };
      },
    }),
    getDatafones: builder.query({
      query: () => {
        return {
          url: `Caja/Verifones`,
        };
      },
    }),
    saveBillAdmin: builder.mutation({
      query: (params) => {
        return {
          url: `Caja/Admin/RealizarPago/${params.peopleId}`,
          method: "POST",
          body: params.data,
        };
      },
    }),
    saveInvoicePersonDataAdmin: builder.mutation({
      query: (body) => {
        const id = body.peopleId;
        delete body.peopleId;
        return {
          url: `Caja/EstudianteTercero/${id}`,
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const {
  useGetPaymentsComparisonMutation,
  useGetCartAdminMutation,
  useAssignCartToCashierMutation,
  useDeleteCartItemAdminMutation,
  useSaveBillAdminMutation,
  useGetDatafonesQuery,
  useSaveInvoicePersonDataAdminMutation,
} = chargesSettingsServices;
