import { ISubject } from "@/Interfaces/subject";
import {
  LargeHeadingStyled,
  SectionHeaderStyled,
  SmallHeadingStyled,
} from "../../../components/custom/texts/texts.styled";
import { Box, Card, Flex } from "@nubeteck/components";
import { Button, Form, Image, Layout, Modal, Select, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import {
  useChangeSubjectMutation,
  useGetCurrentSubjectsDetailMutation,
  useGetSubjectsForChangeMutation,
  useGetWithdrawMessageMutation,
  useWithdrawSubjectMutation,
} from "src/services/subjectsServices";
import toast from "react-hot-toast";
import NoResults from "src/assets/NoResults.svg";
import { toastErrorStyle, toastSuccessStyle, toastWarningStyle } from "src/constants";
import { useGetResolutionsMutation } from "src/services";
import { ArrayUtils } from "@nubeteck/utils";
import { SubjectTableSchedule } from "src/components";
import { FormatterUtils } from "@nubeteck/utils";
import { ButtonAlternativeStyled } from "src/components/custom/buttons/buttons.styled";
// import { useGetCareersByStudentIdMutation } from "src/services";
// import { useSelector } from "react-redux";
// import { RootState } from "src/core/store/store";

const { Content } = Layout;

interface ISpecificCourse extends ISubject {
  seccionId: number;
  periodoId: number;
  puedeRetirar: boolean;
  puedeCambiarSeccion: boolean;
}

const Subjects: React.FC = () => {
  const [getSubjects, { data, isLoading }] = useGetCurrentSubjectsDetailMutation();
  const [getSubjectsForChange, { data: subjectsForChange, isLoading: isLoadingSubjectsForChange }] =
    useGetSubjectsForChangeMutation();
  const [changeSubject, { isSuccess: isSuccessChange, error: errorChange }] =
    useChangeSubjectMutation();
  const [getWithdrawMessage] = useGetWithdrawMessageMutation();
  const [
    withdrawSubject,
    { isLoading: isLoadingWithdraw, isSuccess: isSuccessWithdraw, error: errorWithdraw },
  ] = useWithdrawSubjectMutation();
  const [getResolutions, { isLoading: isLoadingResolutions, data: dataResolutions }] =
    useGetResolutionsMutation();

  const [materiaParaRetiro, setMateriaParaRetiro] = useState<ISpecificCourse>();
  const [resolucionId, setResolucionId] = useState();
  const [mensajeRetiro, setMensajeRetiro] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalSelectionOpen, setModalSelectionOpen] = useState(false);
  const [subjectChangeParams, setSubjectChangeParams] = useState<any>({});
  // const { userInfo } = useSelector((state: RootState) => state.user);
  // const [getCareers, { data: careers }] = useGetCareersByStudentIdMutation();
  // const [carreraId, setCarreraId] = React.useState();

  // useEffect(() => {
  //   getCareers(userInfo.id);
  // }, [userInfo, getCareers]);

  // useEffect(() => {
  //   if (careers?.data?.length == 1) {
  //     setCarreraId(careers?.data[0].carreraId);
  //   }
  // }, [careers]);

  const resolutionsOptions = ArrayUtils.selectLabelValue(
    dataResolutions?.data ?? [],
    "resolucionNombre",
    "resolucionId",
  );

  useEffect(() => {
    // if (carreraId) {
    getSubjects();
    // }
    getResolutions();
  }, [getSubjects, getResolutions, isSuccessWithdraw, isSuccessChange]);

  useEffect(() => {
    if (isSuccessWithdraw) {
      toast.success("¡Asignatura retirada correctamente!", toastSuccessStyle);
      setIsModalOpen(false);
    }
  }, [isSuccessWithdraw]);

  useEffect(() => {
    if (errorWithdraw) {
      toast.error(
        errorWithdraw?.data?.message ?? JSON.parse(errorWithdraw?.data)?.message,
        toastErrorStyle,
      );
    }
  }, [errorWithdraw]);
  useEffect(() => {
    if (isSuccessChange) {
      setModalSelectionOpen(false);
      setSubjectChangeParams({});
      toast.success("¡Sección cambiada correctamente!", toastSuccessStyle);
    }
  }, [isSuccessChange]);

  useEffect(() => {
    if (errorChange) {
      setModalSelectionOpen(false);
      setSubjectChangeParams({});
      toast.error(
        errorChange?.data?.message ?? JSON.parse(errorChange?.data)?.message,
        toastErrorStyle,
      );
    }
  }, [errorChange]);

  useEffect(() => {
    if (materiaParaRetiro) {
      getWithdrawMessage(materiaParaRetiro.seccionId).then((mensaje: any) =>
        setMensajeRetiro(mensaje?.data?.data),
      );
    }
  }, [materiaParaRetiro, getWithdrawMessage]);

  useEffect(() => {
    if (mensajeRetiro && materiaParaRetiro) {
      setIsModalOpen(true);
    }
  }, [mensajeRetiro, materiaParaRetiro]);

  useEffect(() => {
    if (isModalOpen == false) {
      setMateriaParaRetiro(undefined);
      setResolucionId(undefined);
    }
  }, [isModalOpen]);

  const getSesion = (monedaId: number) => {
    return monedaId === 2 ? "₡" : "$";
  };

  const columns = React.useMemo<ColumnsType<ISpecificCourse>>(
    () => [
      {
        title: "Código",
        dataIndex: "asignaturaCodigo",
      },
      {
        title: "Asignatura",
        dataIndex: "asignaturaNombre",
      },
      {
        title: "Créditos",
        dataIndex: "creditos",
      },
      {
        title: "Sección",
        dataIndex: "asignaturaSeccionCodigo",
      },
      {
        title: "Horario",
        dataIndex: "horarios",
      },
      {
        title: "Periodo",
        dataIndex: "periodoName",
        width: 100,
      },
      {
        title: "",
        dataIndex: "asignaturaId",
        fixed: "right",
        width: 100,
        render: (_, item) =>
          item.puedeRetirar ? (
            <Button
              type="text"
              loading={isLoadingResolutions}
              onClick={() => setMateriaParaRetiro(item)}
              style={{ borderColor: "#dc3e3e", color: "#dc3e3e" }}
            >
              Retirar
            </Button>
          ) : null,
      },
      {
        title: "",
        dataIndex: "asignatura",
        fixed: "right",
        width: 200,
        render: (_, item) =>
          item.puedeCambiarSeccion ? (
            <ButtonAlternativeStyled
              type="text"
              onClick={() => {
                setModalSelectionOpen(true);
                getSubjectsForChange({ periodoId: item.periodoId, seccionId: item.seccionId });
                setSubjectChangeParams({
                  seccionViejaId: item.seccionId,
                  periodoId: item.periodoId,
                  seccionNuevaId: 0,
                });
              }}
            >
              Cambiar sección
            </ButtonAlternativeStyled>
          ) : null,
      },
    ],
    [setModalSelectionOpen, getSubjectsForChange, isLoadingResolutions],
  );

  return (
    <Flex style={{ flexDirection: "column", width: "100%", alignSelf: "flex-start" }}>
      <SectionHeaderStyled>
        <LargeHeadingStyled>Asignaturas inscritas</LargeHeadingStyled>
        {/* <Form.Item label="Filtro por carrera">
          <Select
            allowClear
            value={carreraId}
            options={careers?.data?.map((c: any) => ({
              label: c.carreraNombre,
              value: c.carreraId,
            }))}
            onChange={(value) => {
              setCarreraId(value);
            }}
            placeholder="Seleccione una carrera"
          />
        </Form.Item> */}
      </SectionHeaderStyled>
      <Content style={{ marginTop: "16px" }}>
        <Card bodyStyle={{ padding: 0, width: "100%" }}>
          <Table<ISpecificCourse>
            locale={{
              emptyText: (
                <Box>
                  <Box>
                    <SmallHeadingStyled>
                      No se encontraron asignaturas inscritas
                      {/* {carreraId
                        ? "No se encontraron asignaturas inscritas"
                        : "Seleccione una carrera para mostrar las asignaturas correspondientes"} */}
                    </SmallHeadingStyled>
                    <Image
                      src={NoResults}
                      preview={false}
                      alt="Ilustración sin resultados"
                      width={200}
                    />
                  </Box>
                </Box>
              ),
            }}
            loading={isLoading || isLoadingWithdraw}
            columns={columns}
            dataSource={data?.data}
            rowKey={(v) => `${v.asignaturaCodigo} ${v.asignaturaNombre}`}
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true,
              style: { marginRight: "30px" },
            }}
            scroll={{ x: 800 }}
          />
        </Card>
      </Content>
      <Modal
        title={`¿Está seguro(a) de que desea retirar la asignatura de ${materiaParaRetiro?.asignaturaNombre}?`}
        cancelText="Cancelar"
        okText="Retirar asignatura"
        confirmLoading={isLoadingWithdraw}
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        onOk={() => {
          if (resolucionId === undefined) {
            toast.error("Debe seleccionar un motivo de retiro", toastWarningStyle);
          } else {
            withdrawSubject({
              seccionId: materiaParaRetiro?.seccionId,
              resolucionId: resolucionId,
            });
          }
        }}
        closable={false}
      >
        <div>
          <Form.Item label="Motivo de retiro">
            <Select
              value={resolucionId}
              loading={isLoadingResolutions}
              options={resolutionsOptions}
              placeholder="Seleccione un motivo"
              onChange={(e) => {
                setResolucionId(e);
              }}
            />
          </Form.Item>
          <p>{mensajeRetiro}</p>
        </div>
      </Modal>
      <Modal
        width="fit-content"
        closable={false}
        open={modalSelectionOpen}
        onCancel={() => setModalSelectionOpen(false)}
        okText="Guardar Sección"
        onOk={() => changeSubject(subjectChangeParams)}
        okButtonProps={{ disabled: subjectChangeParams.seccionNuevaId == 0 }}
        cancelText="Cancelar"
        title={`Cambio de la sección de la asignatura ${subjectsForChange?.data[0]?.asignaturaNombre}`}
      >
        {" "}
        <SubjectTableSchedule
          isLoading={isLoadingSubjectsForChange}
          secciones={subjectsForChange?.data[0]?.secciones?.map((sc: any) => {
            const cupo: string = sc.cupo;
            return {
              ...sc,
              cupo,
              seccionPrecio: `${getSesion(
                subjectsForChange?.data?.monedaId ?? 2,
              )} ${FormatterUtils.number((sc.seccionPrecio as number)?.toFixed(2))}`,
              acciones: (
                <Button
                  type="link"
                  disabled={cupo === "lleno" || cupo == "0" || cupo === "Indefinido"}
                  onClick={() =>
                    setSubjectChangeParams({
                      ...subjectChangeParams,
                      seccionNuevaId:
                        subjectChangeParams.seccionNuevaId == sc.seccionId ? 0 : sc.seccionId,
                    })
                  }
                >
                  {subjectChangeParams.seccionNuevaId == sc.seccionId
                    ? "Remover selección"
                    : "Seleccionar"}
                </Button>
              ),
            };
          })}
        />
      </Modal>
    </Flex>
  );
};

export default Subjects;
