import {
  LargeHeadingStyled,
  SectionHeaderStyled,
} from "../../../components/custom/texts/texts.styled";
import { Card, Flex, IUploadProps, Icon } from "@nubeteck/components";
import { Button, Table, Upload } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { toastErrorStyle } from "src/constants";
import { FormatterUtils } from "@nubeteck/utils";
import { getFormData } from "src/utils/files-util";
import { Content } from "antd/es/layout/layout";
import { ButtonAlternativeStyled } from "src/components/custom/buttons/buttons.styled";
import { useGetPaymentsComparisonMutation } from "src/services/chargesSettingsServices";

const PaymentsComparison: React.FC = () => {
  const [getPaymentsComparison, { data, isLoading, error }] = useGetPaymentsComparisonMutation();
  const [result, setResult] = useState<any>([]);

  useEffect(() => {
    if (error) toast.error(error?.data?.message, toastErrorStyle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data?.data?.pagosExcel?.length > 0) {
      const arrResult: any[] = [];

      data?.data?.pagosExcel.forEach((element: any) => {
        const coincidence = data?.data?.pagosDB?.find(
          (pago: any) => pago?.pagoId == element?.pagoIdMatch,
        );
        arrResult.push({ ...element, ...coincidence });
      });
      // const other = data?.data?.pagosDB?.filter(
      //   (pago: any) => !arrResult.find((el) => el.pagoId === pago.pagoId),
      // );
      // arrResult = arrResult.concat(other);
      setResult(arrResult);
    }
  }, [data]);

  const rowClassName = (record: any) => {
    return record?.pagoIdMatch ? "row-match" : "";
  };

  const descargarExcel: (fileName: string, data: string) => void = (fileName, data) => {
    const aElement = document.createElement("a");
    aElement.setAttribute("download", fileName);
    const href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`;
    aElement.href = href;
    aElement.setAttribute("target", "_blank");
    aElement.click();
    URL.revokeObjectURL(href);
  };

  const columnsExcel = React.useMemo<ColumnsType<any>>(
    () => [
      {
        title: "Pagos en el excel",
        children: [
          {
            title: "No.",
            dataIndex: "numeroPedido",
          },
          {
            title: "Fecha",
            dataIndex: "fechaRegistro",
            width: 100,
          },
          {
            title: "Nombre",
            dataIndex: "nombres",
            width: 250,
            render: (value, record) => `${value} ${record.apellidos}`,
          },
          {
            title: "Monto",
            dataIndex: "monto",
            width: 150,
            render: (value: number) => FormatterUtils.number(value?.toFixed(2)),
          },
          {
            title: "Moneda",
            dataIndex: "moneda",
            width: 150,
          },
          {
            title: "No. tarjeta",
            dataIndex: "numeroTarjeta",
            width: 150,
          },
          {
            title: "Marca tarjeta",
            dataIndex: "marca",
            width: 150,
          },
          {
            dataIndex: "codigoAutorizacion",
            title: "Codigo de autorización",
            width: 80,
          },
          {
            title: "Estado",
            dataIndex: "estaActivo",
            render: (value: any) => (value ? "Activo" : "Inactivo"),
            width: 100,
          },
        ],
      },

      {
        title: <Icon name="" size={26} color="#00479B" />,
        dataIndex: "pagoIdMatch",
        render: (value: any) => {
          return {
            children: (
              <div>
                {value ? (
                  <Icon name="" size={16} color="#00479B" />
                ) : (
                  <Icon name="" size={16} color="#d00000" />
                )}
              </div>
            ),
            props: {
              style: { backgroundColor: "white" },
            },
          };
        },
        width: 60,
      },

      {
        title: "Pagos en el sistema",
        children: [
          {
            title: "Referencia",
            dataIndex: "referencia",
          },
          {
            title: "Fecha",
            dataIndex: "fechaFormateada",
            width: 100,
          },
          {
            title: "Nombre",
            dataIndex: "terceroNombre",
            width: 250,
          },
          {
            title: "Moneda",
            dataIndex: "monedaNombre",
            width: 100,
          },
        ],
      },
    ],
    [],
  );

  const props: IUploadProps = {
    name: "model",
    multiple: false,
    showUploadList: false,
    maxCount: 1,
    beforeUpload: () => false,
    accept: "*",
  };

  const onChangeExcel = (file: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result !== null)
        fetch(reader.result as string).then((res) =>
          res.blob().then((blob) => {
            getPaymentsComparison(getFormData({ file: blob }));
          }),
        );
    };
    reader.readAsDataURL(file);
  };

  return (
    <Flex style={{ flexDirection: "column", width: "100%", alignSelf: "flex-start" }}>
      <LargeHeadingStyled>Verificación de pagos</LargeHeadingStyled>
      <Content style={{ marginTop: "16px" }}>
        {data?.data?.pagosExcel?.length > 0 ? (
          <SectionHeaderStyled style={{ paddingBottom: 7 }}>
            <Upload
              {...props}
              disabled={isLoading}
              onChange={(info) => {
                const { status } = info.file;
                if (status !== "uploading") {
                  onChangeExcel(info.file);
                }
              }}
            >
              <Button icon={<Icon name="" size={13} color="#00479B" />}>Subir otro excel</Button>
            </Upload>
            <Button
              title=""
              type="primary"
              onClick={() =>
                descargarExcel(
                  `Comparación de pagos ${new Date().toLocaleString()}`,
                  data?.data?.excelComparado,
                )
              }
            >
              Exportar pagos
            </Button>
          </SectionHeaderStyled>
        ) : null}

        <Card bodyStyle={{ padding: 0, width: "100%" }} style={{ width: "100%" }}>
          <Table<any>
            bordered
            showHeader={data?.data?.pagosExcel?.length > 0}
            rowClassName={rowClassName}
            locale={{
              emptyText: (
                <>
                  <Upload
                    {...props}
                    disabled={isLoading}
                    onChange={(info) => {
                      const { status } = info.file;
                      if (status !== "uploading") {
                        onChangeExcel(info.file);
                      }
                    }}
                  >
                    <ButtonAlternativeStyled
                      size="large"
                      icon={<Icon name="" size={16} color="#00479B" />}
                    >
                      Subir excel
                    </ButtonAlternativeStyled>
                  </Upload>
                  <br />
                  <p style={{ color: "#8d8d8d" }}>
                    Suba un archivo de excel para mostrar las comparaciones entre los pagos
                    realizados y los pagos reportados.
                  </p>
                </>
              ),
            }}
            loading={isLoading}
            columns={columnsExcel}
            dataSource={result}
            rowKey={(v) => `${v?.identificador} ${v?.pagoId}`}
            pagination={{
              defaultPageSize: 15,
              showSizeChanger: true,
              style: { marginRight: "30px" },
            }}
            scroll={{ x: 200 }}
          />
        </Card>
      </Content>
    </Flex>
  );
};

export default PaymentsComparison;
