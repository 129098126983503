import {
  LargeHeadingStyled,
  SectionHeaderStyled,
} from "../../../components/custom/texts/texts.styled";
import { Flex } from "@nubeteck/components";
import { Layout, Tabs, TabsProps } from "antd";
import React from "react";
import GeneralSettingsView from "../generalSettings/generalSettings";
import { Services } from "../servicesAdmin";
import { Users } from "../users";
import { BanksSettings } from "../banksSettings";
import { EmailTemplates } from "../emailTemplates";
import { Sponsors } from "../sponsors";
import { RequestsConfig } from "../requestConfig";
import { Resolutions } from "../resolutions";

const { Content } = Layout;

const Settings: React.FC = () => {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Configuraciones generales`,
      children: <GeneralSettingsView />,
    },
    {
      key: "2",
      label: `Servicios`,
      children: <Services />,
    },
    {
      key: "4",
      label: `Bancos`,
      children: <BanksSettings />,
    },
    {
      key: "5",
      label: `Plantillas`,
      children: <EmailTemplates />,
    },
    {
      key: "6",
      label: `Patrocinadores`,
      children: <Sponsors />,
    },
    {
      key: "7",
      label: `Solicitudes`,
      children: <RequestsConfig />,
    },
    {
      key: "8",
      label: `Resoluciones`,
      children: <Resolutions />,
    },
    {
      key: "9",
      label: `Usuarios`,
      children: <Users />,
    },
  ];

  return (
    <Flex style={{ flexDirection: "column", width: "100%", alignSelf: "flex-start" }}>
      <SectionHeaderStyled>
        <LargeHeadingStyled>Configuraciones</LargeHeadingStyled>
      </SectionHeaderStyled>

      <Content style={{ marginTop: "16px" }}>
        <Tabs
          tabBarStyle={{
            justifyContent: "space-between",
            width: "100%",
          }}
          defaultActiveKey="month"
          items={items}
        />
      </Content>
    </Flex>
  );
};

export default Settings;
