import { api } from "./apiBaseQuery";

export const resolutionsServices: any = api.injectEndpoints({
  endpoints: (builder) => ({
    getResolutionsByStatus: builder.mutation({
      query: (estadoId) => {
        return {
          url: `/Resoluciones/PorEstado?estadoId=${estadoId}`,
          method: "GET",
        };
      },
    }),
    getResolutions: builder.mutation({
      query: () => {
        return {
          url: `/Resoluciones`,
          method: "GET",
        };
      },
    }),
    getResolutionsStatus: builder.mutation({
      query: () => {
        return {
          url: `/Resoluciones/Estados`,
          method: "GET",
        };
      },
    }),
    updateResolution: builder.mutation({
      query: (body) => {
        const id = body.id;
        delete body.id;
        return {
          url: `/Resoluciones/${id}`,
          method: "PUT",
          body,
          responseHandler: "text",
        };
      },
    }),
    deleteResolution: builder.mutation({
      query: (body) => {
        const id = body.resolucionId;
        delete body.resolucionId;
        return {
          url: `/Resoluciones/${id}`,
          method: "PUT",
          body,
          responseHandler: "text",
        };
      },
    }),
    createResolution: builder.mutation({
      query: (body) => {
        return {
          url: `/Resoluciones`,
          method: "POST",
          body,
          responseHandler: "text",
        };
      },
    }),
  }),
});

export const {
  useGetResolutionsMutation,
  useGetResolutionsByStatusMutation,
  useGetResolutionsStatusMutation,
  useUpdateResolutionMutation,
  useCreateResolutionMutation,
  useDeleteResolutionMutation,
} = resolutionsServices;
